<template>
  <div class="content">
    <div class="box-content">
      <div v-if="!isLoading">
        <b-row
          class="justify-content-around align-items-center ma-3 rounded-lg"
        >
          <b-col
            sm="2"
            md="2"
            lg="2"
            xl="2"
            cols="10"
            align="center"
          >
            <h5>Auditar Volume:</h5>
          </b-col>
          <b-col
            cols="11"
            sm="7"
            md="5"
            lg="5"
            xl="6"
            class=""
            style="margin-top: 15px"
          >
            <b-form-input
              label="Buscar Volume"
              placeholder="Buscar Volume"
              v-model="volumeId"
              @keydown.enter="search(volumeId)"
              dense
              solo
            ></b-form-input>
          </b-col>
          <b-col cols="5" sm="5" md="2" lg="1" xl="1" align="center">
            <b-button
              :disabled="!volumeId"
              :loading="isLoadingButton"
              class="custom-orange-button"
              @click="clear()"
              style="text-transform: none !important; font-weight: bolder"
            >
              Limpar
            </b-button>
          </b-col>
          <b-col cols="5" sm="5" md="2" lg="2" xl="2" align="center">
            <b-button
              :disabled="!volumeId"
              :loading="isLoadingButton"
              class="custom-orange-button"
              @click="search(volumeId)"
              style="text-transform: none !important; font-weight: bolder"
            >
              Validar Volume
            </b-button>
          </b-col>
          <!-- <b-col cols="1" sm="2" md="1" lg="1" xl="1" align="center">
            <router-link
              :to="{
                name: 'ListagemDeAuditoria',
              }"
            >
              <b-button
                class="custom-orange-button"
                @click="goToVolume()"
                style="text-transform: none !important; font-weight: bolder"
              >
                <b-icon icon="arrow-right-circle-fill" font-scale="1"></b-icon>
              </b-button>
            </router-link>
          </b-col> -->
        </b-row>
        <div v-if="Object.keys(volumeData).length > 0" class="">
          <b-row class="justify-content-center">
            <b-col
              cols="12"
              sm="12"
              md="4"
              lg="4"
              xl="3"
              class="mx-1"
              align="center"
            >
              <b-icon icon="box-seam" color="#e6792e" font-scale="10"></b-icon>
              <h3 class="font-weight-bold">
                N. Baia: {{ volumeData.baiaNome }}
              </h3>
              <span>Transportadora: {{ volumeData.transportadoraNome }}</span>
            </b-col>

            <b-col
              cols="12"
              sm="7"
              md="6"
              lg="6"
              xl="6"
              class="ml-4 text-start"
            >
              <div>
                <h5 class="font-weight-bold mt-4">Detalhes do Pedido</h5>

                <div class="pa-1 ma-0">
                  <span class="ma-2 pa-2" style="margin: 10px"
                    >Pedido: {{ volumeData.numeroPedido }}</span
                  >
                </div>
                <div class="pa-1 ma-0">
                  <span class="ma-2 pa-2" style="margin: 10px"
                    >Cliente: {{ volumeData.clienteNome }}</span
                  >
                </div>
                <!-- <div class="pa-1 ma-0">
    <sp class="ma-2 pa-2" style="margin: 10px;"an>Volume: {{ volumeData.volumeId }}</sp>
  </div> -->
                <!-- <div class="pa-1 ma-0">
    <sp class="ma-2 pa-2" style="margin: 10px;"an
      >Data Despacho:
      {{ volumeData.dataDespacho | formatDate }}</sp
    >
  </div> -->
                <div class="pa-1 ma-0">
                  <span class="ma-2 pa-2" style="margin: 10px"
                    >Data Produção:
                    {{ volumeData.dataProducao | formatDate }}</span
                  >
                </div>
                <!-- <div class="pa-1 ma-0">
  <span class="ma-2 pa-2" style="margin: 10px"
    >Localização: {{ volumeData.localizacaoEstoque }}</span
  >
</div> -->
                <div class="pa-1 ma-0">
                  <span class="ma-2 pa-2" style="margin: 10px"
                    >Kit: {{ volumeData.kitDescricao }}</span
                  >
                </div>
              </div>
              <!-- <div class="pa-0 ma-0">
                <h3 class="font-weight-bold">
                  N. Baia: {{ volumeData.transportadoraNome }}
                </h3>
              </div>

              <div class="pa-1 ma-0">
                <span class="ma-2 pa-2" style="margin: 10px"
                  >Transportadora: {{ volumeData.transportadoraNome }}</span
                >
              </div> -->
            </b-col>
          </b-row>
          <b-row class="justify-content-center mt-4">
            <b-button
              @click="confirmVolume(volumeData.coletaId)"
              size="lg"
              :loading="isLoadingBtnDriver"
              block
              class="custom-orange-button"
              style="
                text-transform: none !important;
                font-weight: bolder;
                max-width: 220px;
              "
            >
              Confirmar volume
            </b-button>
          </b-row>
        </div>

        <div v-else>
          <div
            class="box-element justify-center align-center mx-3 my-auto mt-3 rounded-lg base_dark text--text"
          >
            <b-col
              class="align-self-center ma-5 text-center"
              cols="12"
              v-if="!isLoadingButton"
            >
              <span v-if="conditionMessage"
                >Favor inserir o Número do volume.</span
              >
              <span v-else> Erro ao buscar Volume.</span>
            </b-col>
            <b-col
              class="align-self-center ma-5 text-center"
              cols="12"
              v-if="isLoadingButton"
            >
              <span>Aguarde enquanto buscamos informações do protocolo...</span>
            </b-col>
          </div>
        </div>
      </div>
      <b-col v-else>
        <b-progress
          v-model="isLoading"
          variant="primary"
          indeterminate
        ></b-progress>
      </b-col>
    </div>
    <modal-error
      @close="showModal = false"
      :modalAlert="modalAlert"
    ></modal-error>
  </div>
</template>
<script>
import { AuditoriaProtocoloService } from "../service/AuditoriaProtocoloService";
import ModalError from "../../../modules/souserials/producao_pedido/ModalError.vue";

export default {
  name: "AuditoriaVolume",
  components: {
    ModalError,
  },
  data: () => ({
    isLoadingBtnDriver: false,
    auditoriaProtocoloService: AuditoriaProtocoloService.build(),
    isLoading: false,
    isLoadingButton: false,
    showModal: false,
    conditionMessage: true,
    volumeData: {},
    volumeId: "",
    volumeIdConfirmed: "",
    modalAlert: {
      message: "",
      variant: "",
    },
  }),
  created() {
    this.auditoriaProtocoloService =
      AuditoriaProtocoloService.build().setVm(this);
  },
  mounted() {
    this.auditoriaProtocoloService.setVm(this);
  },
  computed: {},
  methods: {
    async search() {
      this.volumeData = {};
      this.isLoadingButton = true;
      await this.auditoriaProtocoloService.setSearch(this.volumeId);
      this.volumeIdConfirmed = this.volumeId;
      this.isLoadingButton = false;
    },

    async confirmVolume() {
      this.isLoadingBtnDriver = true;
      await this.auditoriaProtocoloService.confirmVolume(
        this.volumeIdConfirmed
      );
      this.isLoadingBtnDriver = false;
    },
    clear() {
      this.volumeId = null;
      this.volumeData = {};
    },
  },
  filters: {
    formatDate(dateToBeFormatted) {
      return new Date(dateToBeFormatted).toLocaleDateString("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
    formatHour(dateToBeFormatted) {
      const formatter = new Intl.DateTimeFormat("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });
      const date = new Date(dateToBeFormatted);
      return formatter.format(date);
    },
  },
  watch: {
    volumeId: {
      handler(volumeId) {
        this.volumeId = volumeId;
      },
      immediate: true,
    },
  },
};
</script>
<style scoped>
.box-element {
  background-color: #ffffff;
  min-height: 30px;
}
.h {
  font-weight: bolder;
}
.custom-orange-button {
  background-color: #cc5d02;
  color: white;
  border: none;
  outline: none;
}
</style>
