import { HttpRestService } from "@/services/http/HttpRestService";

export default class BaiaAuditoriaRepository {
  static build() {
    return new BaiaAuditoriaRepository();
  }

  getPedidoPerVolume(volume) {
    return HttpRestService.get(`/api/v2/triagem/${volume}/baia`);
  }

  postConfirmErrorPedido(volumeId) {
    return HttpRestService.post(
      `/api/v2/triagem/confirmar-error-triagem/${volumeId}/volume`
    );
  }

  getListagem(urlParams) {
    const queryStrings = {
      page: urlParams.page,
      perPage: 10,
      dataInicio: urlParams.filtros?.dataInicio ?? null,
      dataFinal: urlParams.filtros?.dataFinal ?? null,
      transportadoraNome: urlParams.filtros?.transportadora ?? null,
      numeroPedido: urlParams.filtros?.numeroPedido ?? null,
    };

    const url = `/api/v2/volume/auditados`;
    return HttpRestService.get(url, queryStrings);
  }
}
