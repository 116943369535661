import BaiaAuditoriaRepository from "../../../repositories/v2/BaiaAuditoriaRepository";

export class AuditoriaProtocoloService {
  vm;
  status;
  loading = false;

  BaiaAuditoriaRepository;

  constructor(BaiaAuditoriaRepository) {
    this.BaiaAuditoriaRepository = BaiaAuditoriaRepository;
  }

  static build() {
    const baiaAuditoriaRepository = BaiaAuditoriaRepository.build();
    return new AuditoriaProtocoloService(baiaAuditoriaRepository);
  }
  setVm(vm) {
    this.vm = vm;
    return this;
  }

  async setSearch(volume) {
    this.loading = true;
    try {
      const response = await this.BaiaAuditoriaRepository.getPedidoPerVolume(
        volume
      );
      if (response.data.statusCode === 200) {
        this.vm.conditionMessage = true;
        this.vm.volumeData = response.data.data;
        this.loading = false;
      } else {
        this.vm.conditionMessage = false;
        throw response;
      }
    } catch (error) {
      console.log(error);
    }
  }
  async confirmVolume(volume) {
    this.loading = true;
    try {
      const response =
        await this.BaiaAuditoriaRepository.postConfirmErrorPedido(volume);
      if (response.data.statusCode === 200) {
        this.vm.modalAlert.message = response.data.message;
        this.vm.modalAlert.variant = "success";
        this.vm.$bvModal.show("modal-error");
        this.vm.volumeData = [];
        this.loading = false;
      } else {
        this.vm.modalAlert.message = response.data.message;
        this.vm.modalAlert.variant = "danger";
        this.vm.$bvModal.show("modal-error");

        this.loading = false;
      }
    } catch (error) {
      console.log(error);
    }
  }
}
